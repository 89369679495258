<template>
    <div class="main-wrapper">
        <NavbarDashboard></NavbarDashboard>
        <SidebarDashboard></SidebarDashboard>
        <div class="main-content">
            <section class="section">
                <h4 class="title">Edit Akun Perkiraan</h4>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/data-akun-perkiraan">Akun Perkiraan </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">History</li>
                    </ol>
                </nav>
                <div class="card info-detail">
                    <div class="row ">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <router-link to="/data-jurnal-perkiraan/detail" class="nav-link ">Detail
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/data-jurnal-perkiraan/history" class="nav-link active">History
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="row  mt-5">
                        <div class="col-12">
                            <div class="title">History Akun Perkiraan</div>
                            <div class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
                        </div>
                    </div>
                    <hr>

                    <div class="table-responsive">
                        <table class="table table-bordered  table-striped " id="tableDepartment">
                            <thead>
                                <tr>
                                    <th style="width:15%">Tanggal</th>
                                    <th style="width:15%;">No. Sumber</th>
                                    <th style="width:20%;">Tipe Transaksi</th>
                                    <th style="width:20%;">Keterangan</th>
                                    <th style="width:20%;">Mutasi</th>
                                    <th style="width:10%;">Tipe</th>
                                    <th style="width:10%">Saldo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>11 Jan 2022</td>
                                    <td>012345678</td>
                                    <td>Saldo Awal</td>
                                    <td>Saldo Awal</td>
                                    <td class="text-right">12,345,678,900</td>
                                    <td>Dr</td>
                                    <td class="text-right">12,345,678,900</td>
                                </tr>
                                <tr>
                                    <td>11 Jan 2022</td>
                                    <td>012345678</td>
                                    <td>Saldo Awal</td>
                                    <td>Saldo Awal</td>
                                    <td class="cr text-right">12,345,678,900</td>
                                    <td>Cr</td>
                                    <td class="text-right">12,345,678,900</td>
                                </tr>
                                <tr>
                                    <td>11 Jan 2022</td>
                                    <td>012345678</td>
                                    <td>Saldo Awal</td>
                                    <td>Saldo Awal</td>
                                   <td class="cr text-right">12,345,678,900</td>
                                    <td>Cr</td>
                                    <td class="text-right">12,345,678,900</td>
                                </tr>
                                <tr>
                                    <td>11 Jan 2022</td>
                                    <td>012345678</td>
                                    <td>Saldo Awal</td>
                                    <td>Saldo Awal</td>
                                    <td class="text-right">12,345,678,900</td>
                                    <td>Dr</td>
                                    <td class="text-right">12,345,678,900</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>





<script>
    import SidebarDashboard from '../../../components/Sidebar.vue'
    import NavbarDashboard from '../../../components/Navbar.vue'
    // import Select2 from 'vue3-select2-component';
    // import Datepicker from 'vue3-date-time-picker';



    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"

    import $ from 'jquery'

    // import TableLite from 'vue3-table-lite'
    export default {
        name: 'dataDepartement',
        components: {
            // FooterDashboard,
            SidebarDashboard,
            NavbarDashboard,
            // Select2,
            // Datepicker
            // TableLite
        },

        data() {
            return {
                myValue: 'aaaa',
                statusCompany: ['Aktif', 'Tidak Aktif'], // or [{id: key, text: value}, {id: key, text: value}]
                optionPeriode: ['1 Bulan', '2 Bulan'],
                date: null,
                date2: null,
                OptionAccountType: ['Kas', 'Kas & Bank', 'BanK'],
                OptionCurrency: ['IDR', 'USD', 'EUR'],
                OptionBranch: ['Yogyakarta', 'Solo', 'Klaten'],

                placeholder: [{
                    text: 'kecamatan'
                }]
            }
        },

        mounted() {
            $('#tableDepartment').DataTable({
                info: false,
            });
        },
        methods: {
            myChangeEvent(val) {
                console.log(val);
            },
            mySelectEvent({
                id,
                text
            }) {
                console.log({
                    id,
                    text
                })
            },
        },


    }
</script>


<style scoped src="../../../assets/css/style.css">
</style>


<style scoped src="../../../assets/css/custom.css">
</style>



<style scoped>
    .cr {
        color: #FF3D3D;
    }

    .btn-save {
        background-color: #40DDD4;
        color: #fff;
        width: 90px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 0px;
    }

    .card .card-title {
        font-size: 18px;
        color: #000;
        font-weight: 500;
    }


    .card .nav .nav-item {
        margin-right: 30px;
    }

    .card {
        padding: 20px 50px;
        widows: 100%;
    }

      .card {
        border: 1px solid #EAEAEA;
        border-radius: 10px;
    }
</style>